import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { registerRoute } from "../utils/APIRoutes";
import Sidebar from "../components/admin/Sidebar";
import AdminHeader from "../components/admin/AdminHeader";
import Inbox from "./admin/Chat";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function Admin(props) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("chat-app-user")) {
      navigate("/login");
    }
  }, []);

  const user = JSON.parse(localStorage.getItem("chat-app-user"));

  const logout = () => {
    localStorage.removeItem("chat-app-user");
    navigate("/login");
  };

  return (
    <>
      <div class="dashboard flex flex-auto min-h-0 flex-row">
        <Sidebar {...props} />
        {props.route}
      </div>
    </>
  );
}

const ChatContainer = styled.div`
  form {
    padding: 3rem 5rem;
  }
`;

export default Admin;
