import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Chat from "./pages/admin/Chat";
import Dashboard from "./pages/admin/Dashboard";
import Admin from "./pages/Admin";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { lightTheme, darkTheme, GlobalStyles } from "./styles/themes.js";

function App() {
  const [theme, setTheme] = useState("light");

  const themeToggler = () => {
    theme === "light" ? setTheme("dark") : setTheme("light");
  };

  const defaultTheme = "light";

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/register"
          element={
            <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
              <GlobalStyles />
              <Register theme={theme} themeFunction={themeToggler} />
            </ThemeProvider>
          }
        />
        <Route
          path="/login"
          element={
            <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
              <GlobalStyles />
              <Login theme={theme} themeFunction={themeToggler} />
            </ThemeProvider>
          }
        />
        <Route
          path="/admin/dashboard"
          element={
            <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
              <GlobalStyles />
              <Admin
                theme={theme}
                themeFunction={themeToggler}
                route={<Dashboard />}
              />
            </ThemeProvider>
          }
        />
        <Route
          path="/admin/chat"
          element={
            <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
              <GlobalStyles />
              <Admin
                theme={theme}
                themeFunction={themeToggler}
                route={<Chat />}
              />
            </ThemeProvider>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
