import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ThemeToggle from "../components/ThemeToggle";
import axios from "axios";
import { loginRoute } from "../utils/APIRoutes";

function Login(props) {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const toastOptions = {
    position: "bottom-right",
    autoClose: "8000",
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  };

  useEffect(() => {
    if (localStorage.getItem("chat-app-user")) {
      navigate("/admin/dashboard");
    }
  }, []);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      setLoading(true);
      const { password, email } = values;
      const { data } = await axios.post(loginRoute, {
        email,
        password,
      });
      if (data.status === false) {
        toast.error(data.msg, toastOptions);
        setLoading(false);
      }
      if (data.status === true) {
        localStorage.setItem("chat-app-user", JSON.stringify(data.user));
        setLoading(false);
        navigate("/admin/dashboard");
      }
    }
  };

  const handleValidation = () => {
    const { password, confirmPassword, email } = values;
    if (password.length === "") {
      toast.error("Username and Password is required", toastOptions);
      return false;
    } else if (email.length === "") {
      toast.error("Email and Password is required", toastOptions);
      return false;
    }
    return true;
  };

  return (
    <>
      <FormContainer className="h-screen w-screen flex flex-col justify-center gap-4 items-center">
        <ThemeToggle theme={props.theme} themeFunction={props.themeFunction} />
        <form
          onSubmit={(event) => handleSubmit(event)}
          className="form flex flex-col gap-4 rounded-3xl"
        >
          <div className="brand py-8">
            <h1 className="text-4xl font-normal leading-normal mt-0">Log In</h1>
            <p className="text-sm">Login in to Chatty</p>
          </div>
          <div>
            <label htmlFor="username">Email</label>
            <input
              type="email"
              placeholder="Please enter your email"
              name="email"
              onChange={(e) => handleChange(e)}
              min="3"
            />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              placeholder="Please enter your password"
              name="password"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            {loading ? <div>Loading</div> : "Log In"}
          </button>
          <span className="text-center">
            Don't have an account?{" "}
            <Link to="/register" className="link">
              Register
            </Link>
          </span>
        </form>
      </FormContainer>
      <ToastContainer />
    </>
  );
}

const FormContainer = styled.div`
  form {
    padding: 3rem 5rem;
  }
`;

export default Login;
