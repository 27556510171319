import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ThemeToggle from "../components/ThemeToggle";
import axios from "axios";
import { registerRoute } from "../utils/APIRoutes";

function Register(props) {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    // username: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [loading, setLoading] = useState(false);

  const toastOptions = {
    position: "bottom-right",
    autoClose: "8000",
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  };

  useEffect(() => {
    if (localStorage.getItem("chat-app-user")) {
      navigate("/admin/dashboard");
    }
  }, []);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      setLoading(true);
      const { password, username, firstName, lastName, email } = values;
      const { data } = await axios.post(registerRoute, {
        // username,
        firstName,
        lastName,
        email,
        password,
      });
      if (data.status === false) {
        toast.error(data.msg, toastOptions);
        setLoading(false);
      }
      if (data.status === true) {
        localStorage.setItem("chat-app-user", JSON.stringify(data.user));
        setLoading(false);
        navigate("/admin/dashboard");
      }
    }
  };

  const handleValidation = () => {
    const { password, confirmPassword, username, firstName, lastName, email } =
      values;
    if (password != confirmPassword) {
      toast.error("Passwords do not match", toastOptions);
      return false;
    } else if (firstName === "") {
      toast.error("First Name is required", toastOptions);
      return false;
    } else if (lastName === "") {
      toast.error("Last Name is required", toastOptions);
      return false;
      // } else if (username.length < 3) {
      //   toast.error(
      //     "Username length should be at least 3 characters",
      //     toastOptions
      //   );
      //   return false;
    } else if (email === "") {
      toast.error("Email is required", toastOptions);
      return false;
    } else if (password.length < 8) {
      toast.error(
        "Password length should be at least 8 characters",
        toastOptions
      );
      return false;
    }
    return true;
  };

  return (
    <>
      <FormContainer className="h-screen w-screen flex flex-col justify-center gap-4 items-center">
        <ThemeToggle theme={props.theme} themeFunction={props.themeFunction} />
        <form
          onSubmit={(event) => handleSubmit(event)}
          className="form flex flex-col gap-4 rounded-3xl"
        >
          <div className="brand py-8">
            <h1 className="text-4xl font-normal leading-normal mt-0">
              Sign Up
            </h1>
            <p className="text-sm">Create an account to start using Chatty</p>
          </div>
          <div className="flex flex-wrap gap-2">
            <div>
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                placeholder="Please enter your first name"
                name="firstName"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div>
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                placeholder="Please enter your last name"
                name="lastName"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          {/* <div>
            <label htmlFor="username">Username</label>
            <input
              type="text"
              placeholder="Please enter a username"
              name="username"
              onChange={(e) => handleChange(e)}
            />
          </div> */}
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              placeholder="Please enter your email"
              name="email"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              placeholder="Please enter a strong password"
              name="password"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div>
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              placeholder="Please confirm your password"
              name="confirmPassword"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            {loading ? <div>Loading</div> : "Create User"}
          </button>
          <span className="text-center">
            Already have an account?{" "}
            <Link to="/login" className="link">
              Login
            </Link>
          </span>
        </form>
      </FormContainer>
      <ToastContainer />
    </>
  );
}

const FormContainer = styled.div`
  form {
    padding: 3rem 5rem;
  }
`;

export default Register;
