import React from "react";

function Messages() {
  return (
    <div className="flex flex-col w-1/5 relative z-10 box shadow">
      <div className="flex-none flex items-center p-6">
        <h3 className="text-3xl font-semibold">Messages</h3>
      </div>
      <div className="flex-auto overflow-y-auto py-5">
        <div className="flex gap-2 px-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
            />
          </svg>
          <span className="text-sm">LIVE</span>
        </div>
        <a className="block recipient">
          <div className="recipient-box px-6 py-3 space-y-4">
            <div className="flex flex-row items-center space-x-2">
              <svg
                className="w-14 h-14"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <div className="flex justify-between w-full">
                <div className="">
                  <strong className="flex-grow text-base font-semibold">
                    Nikola Tesla
                  </strong>
                  <span className="block text-green-500 text-sm">
                    Typing...
                  </span>
                </div>
                <div className="flex flex-col items-end justify-between">
                  <span className="block font-light text-sm">4:30 PM</span>
                  <div>
                    <span className="bg-red-600 h-5 w-5 flex text-white rounded-full text-xs justify-center items-center">
                      2
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
        <a className="block recipient">
          <div className="recipient-box active px-6 py-3 space-y-4">
            <div className="flex flex-row items-center space-x-2">
              <svg
                className="w-14 h-14"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <div className="flex justify-between w-full">
                <div className="">
                  <strong className="flex-grow text-base font-semibold">
                    Nikola Tesla
                  </strong>
                  <span className="block text-green-500 text-sm">
                    Typing...
                  </span>
                </div>
                <div className="flex flex-col items-end justify-between">
                  <span className="block font-light text-sm">4:30 PM</span>
                  <div>
                    <span className="bg-red-600 h-5 w-5 flex text-white rounded-full text-xs justify-center items-center">
                      2
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
        <div className="mt-6">
          <div className="flex gap-2 px-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
              />
            </svg>
            <span className="text-sm">ALL MESSAGES</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Messages;
