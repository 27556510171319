import { createGlobalStyle } from "styled-components";

const elementStyles = {
  mainColour1: "rgb(22, 110, 71)",
  mainColourLight1: "rgba(22, 110, 71, 0.2)",
  borderRadius: "0.5rem",
};

export const lightTheme = {
  body: "#fafafa",
  fontColour: "#000",
  boxColour: "rgba(0, 0, 0, 0.03)",
  border: "#eeeef2",
  sidebarBg: "#ffffff",
  adminBoxBg1: "#ffffff",
};

export const darkTheme = {
  body: "#2b2f42",
  fontColour: "#fff",
  boxColour: "rgba(255, 255, 255, 0.03)",
  border: "#eeeef2",
  sidebarBg: "#242837",
  adminBoxBg1: "#313448",
};

export const GlobalStyles = createGlobalStyle`

    body {
      background-color: ${(props) => props.theme.body};
      color: ${(props) => props.theme.fontColour};
    }

    h1, h2, h3, h4, h5, h6, p {
      color: ${(props) => props.theme.fontColour};
    }

    a.link {
      color: ${elementStyles.mainColour1};
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
    }

    form {
      label {
        font-size: 0.9rem;
      }
      input {
      color: ${(props) => props.theme.fontColour};
      background-color: ${(props) => props.theme.body};;
      padding: 0.5rem;
      border: 0.12rem solid ${(props) => props.theme.border};
      border-radius: ${elementStyles.borderRadius};
      font-size: 0.9rem;
      width: 100%;
      transition: 0.3s ease all;
      &:focus {
        border-color: ${elementStyles.mainColour1};
        outline: none;
        }
      }
    }

    button.btn {
      padding: 0.5rem;
      border: 0.15rem solid transparent;
      font-weight: 500;
      cursor: pointer;
      border-radius: 0.4rem;
      font-size: 1rem;
      text-transform: capitalize;
      transition: 0.3s ease-in-out all;
    }

    button.btn-primary {
      background-color: ${elementStyles.mainColour1};
      color: #ffffff;
      &:hover {
        background-color: ${elementStyles.mainColourLight1};
        color: ${elementStyles.mainColour1};
        border-color: ${elementStyles.mainColour1};
      }
    }

    #admin-sidebar {
      .admin-sidebar-container {
        background-color: ${elementStyles.mainColour1};
      }
      .sidebar-link {
        &:hover {
          background-color: ${(props) => props.theme.boxColour};
        }
      }
      .sidebar-link.active {
        background-color: rgba(255, 255, 255, 0.2);
        } 
     }

     #admin-header {
      .admin-header-container {
        background-color: ${(props) => props.theme.body};
      }
      .sidebar-logo {
        background-color: ${(props) => props.theme.boxColour};
      }
     }

     .dashboard {
       background-color: ${(props) => props.theme.body};
     }

     .box {
      background-color: ${(props) => props.theme.adminBoxBg1};
     }

     .admin-box {
        background-color: ${(props) => props.theme.adminBoxBg1};
        border-radius: ${elementStyles.borderRadius};
        box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
     }

     .recipient {
       .recipient-box {
         &:hover {
          background-color: ${(props) => props.theme.body};
         }
       }
       .recipient-box.active {
        background-color: ${(props) => props.theme.body};
       }
     }

`;
