import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import ThemeToggle from "../ThemeToggle";

const Sidebar = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const navigate = useNavigate();

  return (
    <SidebarContainer>
      <aside aria-label="Sidebar" id="admin-sidebar" className="">
        <div
          className={`h-screen relative admin-sidebar-container duration-300 overflow-hidden shadow-sm ${
            collapsed ? "w-16" : "w-64"
          }`}
        >
          <div className="text-white sidebar-logo h-16 flex justify-center items-center font-semibold text-2xl">
            <Link to="/admin/dashboard">{collapsed ? "C" : "Chatty"}</Link>
          </div>
          <div className="sidebar-content p-3 flex flex-col justify-between">
            <div>
              <ul className="space-y-2">
                <li>
                  <NavLink to="/admin/dashboard" className="sidebar-link">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-shrink-0 w-6 h-6 transition duration-75 group-hover:text-gray-900"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                      />
                    </svg>
                    <div
                      className={`${
                        collapsed && "opacity-0"
                      } origin-left duration-200 flex w-full justify-between`}
                    >
                      <span className="flex-1 ml-3">Dashboard</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/admin/chat" className="sidebar-link">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-shrink-0 w-6 h-6 transition duration-75 group-hover:text-gray-900"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                      />
                    </svg>
                    <div
                      className={`${
                        collapsed && "opacity-0"
                      } origin-left duration-200 flex w-full justify-between`}
                    >
                      <span className="flex-1 ml-3">Messages</span>
                      <span className="inline-flex justify-center items-center p-3 ml-3 w-3 h-3 text-sm font-medium text-green-600 bg-white rounded-full">
                        3
                      </span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
            <div>
              <ul className="mb-5">
                <li>
                  <NavLink exact to="/admin/settings" className="sidebar-link">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-shrink-0 w-6 h-6 transition duration-75 group-hover:text-gray-900"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <div
                      className={`${
                        collapsed && "opacity-0"
                      } origin-left duration-200 flex w-full justify-between`}
                    >
                      <span className="flex-1 ml-3">Settings</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <a
                    className="sidebar-link cursor-pointer"
                    onClick={() => {
                      localStorage.removeItem("chat-app-user");
                      navigate("/login");
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-shrink-0 w-6 h-6 transition duration-75 group-hover:text-gray-900"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      />
                    </svg>
                    <div
                      className={`${
                        collapsed && "opacity-0"
                      } origin-left duration-200 flex w-full justify-between`}
                    >
                      <span className="flex-1 ml-3">Logout</span>
                    </div>
                  </a>
                </li>
              </ul>
              <div
                className={`w-full flex ${
                  collapsed ? "justify-center" : "justify-end"
                }`}
              >
                <button onClick={(e) => setCollapsed(!collapsed)}>
                  <svg
                    className={`w-6 h-6 duration-300 ${
                      !collapsed && "rotate-180"
                    }`}
                    fill="none"
                    stroke="#ffffff"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                </button>
              </div>
              {/* <ThemeToggle
                theme={props.theme}
                themeFunction={props.themeFunction}
              /> */}
            </div>
          </div>
        </div>
      </aside>
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div`
  #admin-sidebar {
    .sidebar-content {
      height: calc(100vh - 4rem);
    }
    ul li {
      margin-top: 0.5rem;
    }
    ul li:first-child {
      margin-top: 0;
    }
    .sidebar-link {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 400;
      border-radius: 0.25rem;
      color: #ffffff;
    }
  }
`;

export default Sidebar;
