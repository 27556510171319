import React from "react";
import styled from "styled-components";

function ChatBox() {
  return (
    <ChatContainer className="w-full flex flex-col relative z-0">
      <div className="flex-none h-20 flex flex-row justify-between items-center p-5 box">
        <div className="flex flex-col space-y-1">
          <strong>Nikola Tesla</strong>
          <input
            type="text"
            placeholder="Add coversation title"
            className="text-sm outline-none border-b border-dashed text-black placeholder-gray-600"
          />
        </div>
      </div>
      <div className="flex-auto overflow-y-auto p-5 space-y-4">
        <div className="flex flex-row space-x-2">
          <svg
            className="flex-none w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div className="flex flex-col">
            <div className="flex items-center gap-3">
              <div className="message-name">Nikola Tesla</div>
              <div className="message-timestamp">10:34 AM</div>
            </div>
            <div className="text-message text-message-in">
              Some message text
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center text-sm text-gray-600">
          You assigned this conversation to yourself 5d ago
        </div>
        <div className="flex flex-row space-x-2 flex-row-reverse space-x-reverse">
          <svg
            className="flex-none w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div className="flex flex-col">
            <div className="flex items-center justify-end gap-3">
              <div className="message-timestamp">10:34 AM</div>
              <div className="message-name">BOFO Ltd</div>
            </div>
            <div className="text-message text-message-out">
              Some message text
            </div>
          </div>
        </div>
      </div>
      <div className="flex-none p-4 pt-0">
        <form className="flex gap-4">
          <input
            type={"text"}
            className="w-full h-full rounded shadow-lg"
            defaultValue={"Hi"}
          />
          <button type="submit" className="btn btn-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 rotate-90"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
              />
            </svg>
          </button>
        </form>
      </div>
    </ChatContainer>
  );
}

const ChatContainer = styled.div`
  .text-message {
    border-radius: 1.5rem;
    padding: 0.7rem 1rem;
    font-weight: 400;
    margin-top: 0.4rem;
  }
  .text-message-in {
    border-top-left-radius: 0;
    background-color: #f0f0f0;
  }
  .text-message-out {
    border-top-right-radius: 0;
    color: #ffffff;
    background-color: rgb(22 110 71);
  }
  .message-name {
    font-weight: 600;
  }
  .message-timestamp {
    font-size: 0.85rem;
  }
  form {
    button {
      border-radius: 999px;
    }
  }
`;

export default ChatBox;
